import React from "react"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Seo from "components/seo"
import Container from "components/container"
import styled from "styled-components"

const StyledContainer = styled(Container)`
	max-width: 1110px;
  	overflow: hidden;
  	padding: 0 10px;
  	margin: 60px auto 80px;
  	
      ${breakpoint.small`
  		padding: 0;
      `}
  	
	  h1 {
	  	font-size: 32px;
		margin: 0 auto;
		text-align: center;
      	margin-inline: 5px;
		
      ${breakpoint.small`
        text-align: left;
      	margin-inline: 35px;
      `}
	  }

	  .categoryName {
		color: ${colors.purpleDark};
		font-size: 19px;
		text-transform: uppercase;
		font-weight: 700;
		text-align: center;
		margin-inline: 5px;

		${breakpoint.small`
			text-align: left;
			margin-inline: 35px;
      `}

	}

	 iframe {
		display: flex;
		min-width: 93%;
		max-width: 96%;
		min-height: 300px;
		max-height: 300px;
		height: 100%;
		margin: 5px auto;
		justify-content: center;
		background: ${colors.black};


      ${breakpoint.small`
		min-height: 450px;
		text-align: left;
      	margin: 10px 35px;
      `}
		
      ${breakpoint.medium`
		min-height: 600px;
      `}
  	}
  }
  
`

const GettingStarted = () => {
	return (
		<React.Fragment>
			<StyledContainer>
			<p className="categoryName">Visby Sexual Health Test</p>
			<h1>Getting Started</h1>
				<iframe
					id="getStarted"
					title="Getting Started"
					src="https://player.vimeo.com/video/779012491?h=500298ae1e&autoplay=1"
					allow="autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture"
					webkitallowfullscreen="true"
					mozallowfullscreen="true"
				></iframe>
			</StyledContainer>
		</React.Fragment>
	)
}

export default GettingStarted

export const Head = () => (
	<Seo
		  title="Getting Started"
		  description="Visby Medical's PCR Sexual Health Test detects chlamydia, gonorrhea, and trichomoniasis in under 30 minutes."
		  image="/meta/getstarted.jpg"
		/>
  )
  